import React, { useEffect, useRef, useState} from 'react';
import './CurrencyInputSDGF.scss';
import {InputChange} from "../../../class/CommonClass";
import {NegativeAlert} from "../negative-alert/NegativeAlert";
interface CurrencyInputSDGFProps {
    value:number;
    name: string;
    placeholder?: string;
    onValueChange?: (value: number, name: string ) => void;
    disabled?: boolean;
    msgLimit?: string;
}


export const CurrencyInputSDGF: React.FC<CurrencyInputSDGFProps> = ({ value, name, msgLimit,disabled, onValueChange}: CurrencyInputSDGFProps) => {

    const [amount, setAmount] = useState<string>('');

    const [disabledInput, setDisabledInput] = useState<boolean>(false);

    const [exeedLimit, setExeedLimit] = useState<boolean>(false);

    const textInput = useRef<HTMLInputElement>(null);

    const limit = 300000000000;//300,000,000,000

    const decimals = 2;

    const  handleInputChange = async (e: InputChange) => {
        let inputValue = e.target.value;
       // console.log('numero input:' + inputValue +'');

     //   console.log('start '+  e.target.selectionStart + ' end ' + e.target.selectionEnd);
        const posSelect = e.target.selectionStart;
        if (inputValue == '$'){
            setAmount('');
            onValueChange!(0,name);// regresar lo que escribamos
        }
        else {

            const entry = inputValue.replace(/[^\d.-]+/g, '');
            let numberFloat: number = parseFloat(entry);
            numberFloat = parseFloat(numberFloat.toFixed(2));
           //      console.log(entry)
            if (!isCentecimal(entry)){
           //     console.log(numberFloat)
                if (!isNaN(Number(numberFloat))){   // validamos si lo que esta escribiendo es un numero válido

                    //    console.log('numero float:' + numberFloat);
                    let validNumberFloat: boolean = false;

                    // validar la longitud para los casos de si es entero o decimal
                    // si es entero
                    //   console.log('modulo:' + numberFloat % 1);
                    if (numberFloat % 1 == 0) {
                        //console.log('Entero' );
                        if (numberFloat.toString().length <= numberFloat.toString().length){
                            if (validLimit(numberFloat)){
                                validNumberFloat = true;
                            }
                        }
                    } else { // es un numero decimal
                        //limit 1 del punto + 2 decimales
                        //     console.log('decimal' );
                        if (numberFloat.toString().length <= limit.toString().length + 1 + decimals){
                            if (validLimit(numberFloat)){
                                validNumberFloat = true;
                            }
                        }
                    }

                    if (validNumberFloat){
                        // console.log('numero currency:' + formatCurrency(numberFloat));
                        const lastDigit = inputValue.substring(inputValue.length-1); // obtener el último digito
                        //console.log('ultimo digito: '+ lastDigit);
                        // si encontramos un . y el al final se lo agregamos
                        // es cuando queremos empezar a agregar un numero con decimal .
                        if (lastDigit == '.' && inputValue.match(/\./g).length==1){
                            setAmount(formatCurrency(numberFloat)+ '.');
                            onValueChange!(numberFloat,name);// regresar lo que escribamos
                        }else {

                            await setAmount(formatCurrency(numberFloat));

                            // decimales con los espacios
                            const numberCurrency = formatCurrency(numberFloat);
                            //       console.log('numberCurrency: ' +numberCurrency);
                            // console.log('match array comas ' + numberCurrency.match(/,/g));
                            // console.log('match array puntos ' + numberCurrency.match(/\./g));

                            //       console.log('start '+  e.target.selectionStart + ' end ' + e.target.selectionEnd);

                            for (let i=0; i<=posSelect; i++){

                                //  console.log('['+ i+ ']'+numberCurrency[i]);
                                if (posSelect == i){
                                    //    console.log('select '+ posSelect);
                                    textInput.current!.selectionStart = (posSelect+1);
                                    textInput.current!.selectionEnd = (posSelect+1);
                                    break;
                                }
                            }

                            onValueChange!(numberFloat,name);// regresar lo que escribamos

                        }
                    }

                }
            }
            else {

                console.log(entry); // Output: 10.0


                setAmount(formatCurrency(entry))
                onValueChange!(numberFloat,name);// regresar lo que escribamos
            }

        }




    }

    const formatCurrency = (number: number): any  => {
        //console.log('number formatCurrency: ' + number);

        // vamos a contar las decimales que vienen
        const numberStr: string = number.toString();

        let startCont= false;
        let contDecimals = 0;
        for (let i=0; i<numberStr.length; i++){

       //     console.log(numberStr[i]);
            if (startCont){
                contDecimals++;
            }
            if (numberStr[i] == '.') {
                startCont = true;
            }

        }

        if (contDecimals>2){
            contDecimals =2;
        }
        //console.log('n decimals: ' + contDecimals);



        return new Intl.NumberFormat('en-US',{
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: contDecimals
        }).format(number)

    };

    const initNumber = (number: number) => {
        // si es entero
        if (number % 1 == 0) {
            if (number.toString().length <= limit.toString().length){
                if (validLimit(number)){
                    setAmount(formatCurrency(value))
                }

            }
        } else { // es un numero decimal
            if (number.toString().length <= limit.toString().length + 1 + decimals){
                if (validLimit(number)){
                    setAmount(formatCurrency(value))
                }
            }
        }
    }

    const validLimit = (number: number) => {
        if (number>=0 && number <= limit){
            setExeedLimit(false); // si el numero es válido
            return true;
        }else {
            setExeedLimit(true); // si no es válido
            return false;
        }
    }

    const isCentecimal = (cent: string) => {

        for (let  i= 0; i< cent.length; i++){
           // console.log('---' +cent.substring(i,i+1));
            if (cent.substring(i,i+1) == '.'){
                if (cent.substring(i+1, i+2) == '0'){
                    return true;
                    break;
                }
            }
        }

        return false;
    }

    const countDecimals = (number: number) => {
        let startCont= false;
        let contDecimals = 0;
        const numberStr: string = number.toString();

        for (let i=0; i<numberStr.length; i++){

            console.log(numberStr[i]);
            if (startCont){
                contDecimals++;
            }
            if (numberStr[i] == '.') {
                startCont = true;
            }

        }

        return contDecimals;
    }


    useEffect(() => {
      //  console.log('value: ' +value);
        if (!isNaN(Number(value))){ // validar que lo que value si sea un nùmero

            if (value==0){
                setAmount(''); // si es 0 imprimir vacío
            }else {
                initNumber(value)

            }

        }

        if (disabled!= undefined){
            setDisabledInput(disabled?true: false)
        }else {
            setDisabledInput(false);
        }



    }, [value, name, msgLimit, disabled]);
    return (
        <>
            <input type="text"
                   className="input-sdgf"
                   id="CurrencyInputLMDS"
                   onChange={handleInputChange}
                   ref={textInput}
                   disabled={disabledInput}
                   value={amount}/>

            {(exeedLimit)?( <NegativeAlert textAlert={msgLimit!}/>): null}



        </>


    );
};

export default CurrencyInputSDGF
