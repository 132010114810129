import React, {Component} from "react";
import { RouteComponentProps} from "react-router-dom";
import {
    IconCheckmark,
    Form,
    Select,
    SelectOption,
    IconSearch, Button, AlphanumericInput, IconData, IconCloseCircle, Toggle, IconBrokenLink, IconChecklist
} from "@lmig/lmds-react";
import '../warranty/Warranty.scss';
import './WarrantyReview.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {PipeLineSteps} from "../../../components/public/pipeline-steps/PipeLineSteps";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {formatValue, InputChange, openNewWindow, validRoles} from "../../../class/CommonClass";
import {PrevPage} from "../../../components/public/prev-page/PrevPage";
import {ButtonSpinner} from "../../../components/public/button-spinner/ButtonSpinner";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import {MessagesAPIComponent} from "@lmig/lf-sgf-library-messages/src";
import environment from "../../../environments/environment";
import {
    initProgreso,
    inputTypeWarranty
} from "../warranty/Warranty";
import {TiposUsoGarantias} from "../../../models/garantias/TiposUsoGarantias";
import {ClavesGarantias} from "../../../models/garantias/ClavesGarantias";
import {MonedaModel} from "../../../models/public/MonedaModel";
import {ExpedienteModel} from "../../../models/garantias/ExpedienteModel";
import {GarantiaModel} from "../../../models/garantias/GarantiaModel";
import {ResponsableGarantiaModel} from "../../../models/garantias/ResponsableGarantiaModel";
import {UserModel} from "../../../models/public/UserModel";
import {ProgresoGarantiaModel} from "../../../models/garantias/ProgresoGarantiaModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import * as apiService from "../../../services/api-gral/ApiGralService";
import {AxiosError} from "axios";
import {
    AuthorizationDocWarranty,
    statesQuoteDoc
} from "../../../components/garantias/authorization-doc-warranty/AuthorizationDocWarranty";
import {PropietarioGarantiaModel} from "../../../models/garantias/PropietarioGarantiaModel";
import {AuthorizationDictumWarranty} from "../../../components/garantias/authorization-dictum-warranty/AuthorizationDictumWarranty";
import {
    AuthorizationAmountWarranty,
    initQuoteAmount
} from "../../../components/garantias/authorization-amount-warranty/AuthorizationAmountWarranty";
import { ExpedientIdentification } from "../../../components/garantias/expedient-identification/ExpedientIdentification";
import {CalificacionMontoModel} from "../../../models/garantias/CalificacionMontoModel";
import { InfoPrincipal } from "../../../components/garantias/info-principal/InfoPrincipal";
import {BreadCumbPrev} from "../../../components/public/breadcumb-prev/BreadCumbPrev";
import {PorcentajePonderacion} from "../../../models/garantias/PorcentajePonderacion";
import {
    AuthorizationPercentWarranty, initQuotePercent
} from "../../../components/garantias/authorization-percent-warranty/AuthorizationPercentWarranty";
import {CalificacionPorcentajeModel} from "../../../models/garantias/CalificacionPorcentajeModel";
import CurrencyInputSDGF from "../../../components/public/currency-input-sdgf/CurrencyInputSDGF";
import {
    AuthorizationVigencyWarranty, initQuoteVigency
} from "../../../components/garantias/authorization-vigency-warranty/AuthorizationVigencyWarranty";
import {CalificacionVigenciaModel} from "../../../models/garantias/CalificacionVigenciaModel";
import {GarantiaDireccionada} from "../../../models/garantias/GarantiaDireccionada";
import {ExpedienteSelect} from "../../../components/garantias/expedient-select/ExpedientSelect";
import {
    initialGarantiaDireccionada,
    initialGarantiaMsg,
    initialGarantiaState
} from "../../../components/garantias/create-warranty/CreateWarranty";
import {GarantiasMessages} from "../../../models/garantias/GarantiasMessages";
import {FaComments} from "react-icons/fa";
import ComentariosFiado from "../../../components/garantias/comentarios-fiado/ComentariosFiado";
import $ from "jquery";

interface Params {
    idFiado: string;
    idFolio: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

interface WarrantyReviewProps{
    language: DefaultStateI
}

class WarrantyReview extends Component<WarrantyReviewProps & RouteComponentProps<Params, SC, LocationState>, WarrantyReviewState>{
    public localStorageClass = new LocalStorageClass();
    public idClient: number;
    public initResponsable: ResponsableGarantiaModel = {
        perfil: '',
        para: '',
        correoPara: '',
    }

    constructor(props: any) {
        super(props);
        this.idClient = parseInt(this.props.match.params.idFiado);

        document.title = "Revisión de Garantía " + parseInt(this.props.match.params.idFolio);
        this.state = {
            idFiado: 0,
            idWarranty:0,
            estadoCaptura: 1,
            listTiposUso: [],
            listClaves: [],
            listMonedas:[],
            listExpediente: [],
            listPercents: [],
            loadingExpediente: true,
            errorExpediente: false,
            garantiaObject: initialGarantiaState,
            garantiaDireccionada: initialGarantiaDireccionada,
            loadingGarantia: true,
            errorGarantia: false,
            quotedAmountWarranty: false,
            quotedPercent: false,
            quotedVigency: false,
            quoteAmont: initQuoteAmount,
            quotePercent: initQuotePercent,
            quoteVigency: initQuoteVigency,
            garantiaMsgs: initialGarantiaMsg,
            propietarioGarantia: this.initResponsable,
            loadingPropietarioGarantia: true,
            errorPropietarioGarantia: false,
            sendingGanrantia: false,
            sendingExpediente: false,
            idGrantiaQueryParam: 0,
            PERFIL:  this.localStorageClass.getProfile(),
            progresoGarantia: initProgreso,
            isExclusiveForPrincipal: false,
            recommendMontoPonderado: 0,
            stablishPorcentajeCalificadora: false,
            showObligors: false,
            openComentario: false,
            read: false
        }
        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)

    }

    render() {
        return <>
          <NavBar/>
            <div className="container-fluid warranty" id="warranty-review">
              
                <div className="">
                    <BreadCumbPrev
                        title={this.props.language.language?.warrantyAuthorization.title!}
                    />
                    <div className="row" id="header-warranty">

                        {(validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
                            validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)) ?
                            (<div className="col-md-12 text-right"
                                  onClick={e =>
                                      this.setState({openComentario: !this.state.openComentario})}>
                                <b className="comments-warranty-review" >
                                    {this.props.language.language?.principalDetail.comments} &nbsp;
                                    <FaComments /></b><br/>
                            </div>) : null
                        }

                        <div className="col-md-12 " id="seccion-warranty-review">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <ExpedientIdentification
                                                idPrincipal={parseInt(this.props.match.params.idFiado)}
                                                showButtons={true}/>
                                        </div>
                                        <div className="col-md-8 ">
                                            <InfoPrincipal
                                                idPrincipal={parseInt(this.props.match.params.idFiado)}
                                                label={this.props.language.language?.warrantyAuthorization.loan!}
                                                openObligors={false}
                                                addOS={false}
                                            />


                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <PipeLineSteps
                                        listSteps={[this.props.language.language?.warranty.pipeline1!,
                                            this.props.language.language?.warranty.pipeline2!,
                                            this.props.language.language?.warranty.pipeline3!,
                                            this.props.language.language?.warranty.pipeline4!]}
                                        currentStep={this.state.estadoCaptura!-1}
                                        clickSteep={show => {
                                            this.openEstadoCaptura(show);
                                        }}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3" id="comentarios-warranty-review">
                            <ComentariosFiado idFiado={this.state.idFiado}
                                              openComentario={this.state.openComentario}/>

                        </div>



                        <div className="col-md-12">
                            <Form id="form-liberty">
                                {this.state.estadoCaptura == 1 ? (
                                    this.state.loadingGarantia ? (
                                    <div className="col-12">
                                        <SpinnerLoading text={this.props.language.language?.warrantyAuthorization.loadingCollateral!}/>
                                    </div>
                                    ) :
                                        (!this.state.errorGarantia)?(
                                            <div className="row">
                                                <div className="col-md-12">

                                                    <Select
                                                        id="idTipoUso"
                                                        isRequired
                                                        name="idTipoUso"
                                                        labelA11y={this.props.language.language?.createWarranty.selectTypeUse!}
                                                        labelVisual={this.props.language.language?.createWarranty.selectTypeUse!}
                                                        value={String(this.state.garantiaObject.idTipoUso)}
                                                        placeholderOption={this.props.language.language?.createWarranty.selectTypeUse!}
                                                        disabled={true}>
                                                        <SelectOption value="0">
                                                            {this.props.language.language?.createWarranty.selectTypeUse!}
                                                        </SelectOption>
                                                        {
                                                            this.state.listTiposUso.map((i, index) =>
                                                                (  <SelectOption key={index} value={ i.idTipo!.toString()}>
                                                                        {i.descripcion}
                                                                    </SelectOption>
                                                                ))
                                                        }

                                                    </Select>

                                                    {/*Si es una garantìa direccionada */}
                                                    {(this.state.garantiaObject.idTipoUso == 1)?(
                                                        <>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <AlphanumericInput
                                                                        name="fianza"
                                                                        type="number"
                                                                        labelVisual={this.props.language.language?.createWarranty.enterBond}
                                                                        value={String(this.state.garantiaDireccionada.fianza)}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <AlphanumericInput
                                                                        name="inclusion"
                                                                        type="number"
                                                                        labelVisual={this.props.language.language?.createWarranty.enterInclusion}
                                                                        value={String(this.state.garantiaDireccionada.inclusion)}
                                                                        disabled={true}
                                                                    />
                                                                    <NegativeAlert textAlert={this.state.garantiaMsgs.msgDirecInclusion}/>
                                                                </div>

                                                            </div>


                                                        </>
                                                    ): null}

                                                    {/*Si es una garantìa exclusiva */}
                                                    {(this.state.garantiaObject.idTipoUso == 2)?(
                                                        <>
                                                            {/*crear un garantía para estas personas o para mi mismo*/}
                                                            <Toggle
                                                                compact
                                                                dynamicWidth
                                                                defaultChecked={this.state.isExclusiveForPrincipal}
                                                                name='isExclusiveMe'
                                                                checked={this.state.isExclusiveForPrincipal}
                                                                labelVisual={this.props.language.language?.createWarranty.exclusiveToMe!} />

                                                            {this.state.isExclusiveForPrincipal?(
                                                                <div id="clients-exclusive">

                                                                    {this.state.garantiaObject.clientesExclusiva.map((i, index) => (
                                                                        <span key={index} className="badge badge-pill badge-light">
                                                                                {i.rfc} | {i.razonSocial}
                                                                    </span>
                                                                    ))}
                                                                </div>
                                                            ): null}

                                                        </>
                                                    ): null}

                                                    <Select
                                                        id="idClave"
                                                        isRequired
                                                        labelA11y={this.props.language.language?.warranty.selectKey!}
                                                        labelVisual={this.props.language.language?.warranty.selectKey!}
                                                        name={'idClave'}
                                                        placeholderOption={this.props.language.language?.warranty.selectKey!}
                                                        value={String(this.state.garantiaObject.idClave)}
                                                        disabled={true}>
                                                        <SelectOption value="0">
                                                            {this.props.language.language?.warranty.selectKey!}
                                                        </SelectOption>
                                                        {
                                                            this.state.listClaves.map((i, index) =>
                                                                (  <SelectOption value={ i.idClave!.toString()}>
                                                                        {i.descripcion}
                                                                    </SelectOption>
                                                                ))
                                                        }


                                                    </Select>
                                                    <Select
                                                        id="idMoneda"
                                                        name="idMoneda"
                                                        isRequired
                                                        labelA11y={this.props.language.language?.warranty.selectCurrency!}
                                                        labelVisual={this.props.language.language?.warranty.selectCurrency!}
                                                        placeholderOption={this.props.language.language?.warranty.selectCurrency!}
                                                        value={String(this.state.garantiaObject.idMoneda)}
                                                        disabled={true}
                                                    >
                                                        <SelectOption value="0">
                                                            {this.props.language.language?.warranty.selectCurrency!}
                                                        </SelectOption>
                                                        {
                                                            this.state.listMonedas.map((i, index) =>
                                                                (  <SelectOption value={ i.idMoneda!.toString()}>
                                                                        {i.abreviacion} -  {i.nombre}
                                                                    </SelectOption>
                                                                ))
                                                        }
                                                    </Select>

                                                </div>
                                                <div className="col-md-12">
                                                    <label className="label-input">{this.props.language.language?.warranty.amount!}:</label>
                                                </div>

                                                <div className="col-md-1" id="check-list-doc">
                                                    <a onClick={() => {
                                                        openNewWindow('/garantias/revision-documentos/' + this.state.idWarranty, 1020, 700)
                                                    }}
                                                       className="d-block mx-auto">
                                                        <IconChecklist
                                                            size="32"
                                                            color="gray"
                                                            />
                                                    </a>
                                                </div>

                                                <div className="col-md-9">
                                                    <CurrencyInputSDGF
                                                        value={this.state.garantiaObject.monto}
                                                        name="monto"
                                                        placeholder={this.props.language.language?.warranty.enterAmount!}
                                                        disabled={true}
                                                        onValueChange={
                                                            (value, name) => {
                                                                this.setState(prevState => ({
                                                                    garantiaObject: { ...prevState.garantiaObject,[name!]: formatValue(value!) }
                                                                }))
                                                                this.initMontoPonderado();
                                                            }
                                                        }

                                                    />

                                                    <NegativeAlert textAlert={this.state.garantiaMsgs.msgMonto}/>

                                                </div>

                                                {(this.state.read == false) &&
                                                    <AuthorizationAmountWarranty
                                                        idWarranty={this.state.idWarranty}
                                                        returnQuote={quote => {
                                                            this.amonutQuoted(quote)
                                                        }
                                                        }/>
                                                }




                                                <div className="col-md-5">
                                                    <label className="label-input">
                                                        {this.props.language.language?.warranty.selectPercent!}:<br/>
                                                        <span className="text-info recommned-percent">
                                                                    *{this.props.language.language?.warranty.recommendPercent+ ' '+
                                                            this.state.recommendMontoPonderado}%</span>
                                                    </label>
                                                </div>

                                                <div className="col-md-5">
                                                    <label className="label-input">
                                                        {this.props.language.language?.warranty.weightedAmount!}:<br/><br/>
                                                    </label>
                                                </div>

                                                <div className="col-md-2"/>

                                                <div className="col-md-5">
                                                    {(!this.state.stablishPorcentajeCalificadora)? (
                                                       <>

                                                           <Select
                                                               id="porcentajePonderacion"
                                                               name="porcentajePonderacion"
                                                               isRequired
                                                               labelA11y={this.props.language.language?.warranty.selectPercent!}
                                                               labelVisual={this.props.language.language?.warranty.selectPercent!}
                                                               onChange={this.handleInputChangeSelect}
                                                               onFocus={()=> {
                                                                   if (this.isValidWarranty(this.state.garantiaObject, false, false)){
                                                                       this.putPorcentajePonderacion(this.state.garantiaObject);
                                                                   }
                                                               }}
                                                               value={String(this.state.garantiaObject.porcentajePonderacion)}
                                                                disabled={(this.state.read== true)}
                                                           >
                                                               <SelectOption value="0" >
                                                                   {this.props.language.language?.warranty.selectOption!}
                                                               </SelectOption>
                                                               {
                                                                   this.state.listPercents.map((i, index) =>
                                                                       (  <SelectOption value={ i.porcentajePonderacion!.toString()} key={index}>
                                                                               {i.porcentajePonderacion}
                                                                           </SelectOption>
                                                                       ))
                                                               }
                                                           </Select>


                                                       </>
                                                    ):
                                                        <>
                                                        <input type="text"
                                                               className="input-sdgf"
                                                               name="porcentajePonderacion"
                                                               value={this.state.garantiaObject.porcentajePonderacion}
                                                               disabled={true}/>
                                                        </>
                                                    }


                                                </div>

                                                <div className="col-md-5">
                                                    <input type="text"
                                                           className="input-sdgf"
                                                           name="montoPonderado"
                                                           value={this.state.garantiaObject.montoPonderado}
                                                           disabled={true}/>
                                                </div>

                                                {(this.state.read == false) &&
                                                <AuthorizationPercentWarranty
                                                        idWarranty={this.state.idWarranty}
                                                        returnQuote={quote => {
                                                            this.percentQuoted(quote)
                                                        }
                                                        }/>
                                                }

                                                <div className="col-md-12">
                                                    <NegativeAlert textAlert={this.state.garantiaMsgs.msgPorcentaje}/>

                                                </div>



                                                <div className="col-md-5">
                                                    <label className="label-input">{this.props.language.language?.warranty.dateStart!}:</label>
                                                </div>

                                                <div className="col-md-5">
                                                    <label className="label-input">{this.props.language.language?.warranty.dateEnd!}:</label>
                                                </div>

                                                <div className="col-md-2"/>
                                                
                                                <div className="col-md-5">

                                                    <input
                                                        type="date"
                                                        name="fechaInicial"
                                                        id="fechaInicial"
                                                        className="input-sdgf"
                                                        value={this.state.garantiaObject.fechaInicial}
                                                        disabled={true}
                                                    />

                                                </div>

                                                <div className="col-md-5">

                                                    <input
                                                        type="date"
                                                        name="fechaFinal"
                                                        id="fechaFinal"
                                                        className="input-sdgf"
                                                        value={this.state.garantiaObject.fechaFinal}
                                                        disabled={true}
                                                    />

                                                </div>
                                                {(this.state.read == false) &&
                                                  <AuthorizationVigencyWarranty
                                                        idWarranty={this.state.idWarranty}
                                                        visibleButton={true}
                                                        returnQuote={quote => {
                                                            this.vigencyQuoted(quote)
                                                        }
                                                        }/>
                                                }



                                                <div className="col-12">
                                                    <NegativeAlert textAlert={this.state.garantiaMsgs.msgVigency}/>
                                                </div>

                                                <div className="col-md-12 buttons-navigation">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <br/>
                                                            <PrevPage/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <br/>
                                                            {!this.state.sendingGanrantia ? (
                                                                    <Button dynamicWidth
                                                                            variant="primary"
                                                                            onClick={() =>this.saveWarranty()}>
                                                                        {this.props.language.language?.warranty.btnContinue!}
                                                                    </Button>
                                                                ):
                                                                <ButtonSpinner label={this.props.language.language?.warranty.btnSending!}/>
                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ):
                                            <div className="col-12">
                                                <NegativeAlert
                                                    textAlert={this.props.language.language?.warrantyAuthorization.errorCollateral!}/>
                                            </div>
                                ): null}

                                {this.state.estadoCaptura == 2 ? (
                                    this.state.loadingExpediente ? (
                                            <div className="col-12">
                                                <SpinnerLoading text={this.props.language.language?.warrantyAuthorization.loadingDoc!}/>
                                            </div>
                                        ) :
                                        (!this.state.errorExpediente)?(
                                                <div className="row">
                                                    {this.state.listExpediente.length>0? (
                                                        <div className="col-md-12 expedient-files">
                                                            {
                                                                this.state.listExpediente.map((i, index) =>
                                                                    (
                                                                        <div key={index} className="expedient">
                                                                            <h5><br/>{i.descripcion} </h5>

                                                                            <hr/>
                                                                            {i.obligatorio?(
                                                                                <b className="text-dark">
                                                                                {this.props.language.language?.warranty.mandatoryDoc!}
                                                                                </b>):null}
                                                                            <div className="row">
                                                                                <div className="col-md-10 col-sm-12">
                                                                                    <div className="row input-sdgf upload-file">

                                                                                        <div className="col-md-2 col-12">
                                                                                            {i.estado == statesQuoteDoc.enRevision? (
                                                                                                <IconCheckmark color="success"
                                                                                                               size="24"
                                                                                                               title={this.props.language.language?.warranty.inReview!}
                                                                                                               className="float-left"/>

                                                                                            ):null}
                                                                                            {i.estado == statesQuoteDoc.enCaptura? (
                                                                                                <IconData color="gray"
                                                                                                          size="24"
                                                                                                          title={this.props.language.language?.warranty.inCapture!}
                                                                                                          className="float-left"/>
                                                                                            ):null}
                                                                                            {i.estado == statesQuoteDoc.aprobado? (
                                                                                                <>
                                                                                                    <IconCheckmark color="success"
                                                                                                                   size="24"
                                                                                                                   title={this.props.language.language?.warranty.approved!}
                                                                                                                   className="float-left"/>

                                                                                                    <IconCheckmark color="success"
                                                                                                                   size="24"
                                                                                                                   title={this.props.language.language?.warranty.approved!}
                                                                                                                   className="float-left"/>
                                                                                                </>

                                                                                            ):null}
                                                                                            {i.estado == statesQuoteDoc.rechazado? (
                                                                                                <IconCloseCircle
                                                                                                    color="error"
                                                                                                    size="24"
                                                                                                    title={this.props.language.language?.warranty.rejected!}
                                                                                                    className="float-left"/>
                                                                                            ):null}
                                                                                        </div>

                                                                                        <div className="col-md-7 col-12 title-doc">
                                                                                            {i.descripcion}
                                                                                        </div>

                                                                                        <div className="col-md-3 col-12 action-buttons">
                                                                                            <div className="row">


                                                                                                {i.url? (
                                                                                                    <a className="col" onClick={() => {
                                                                                                        openNewWindow(i.url!)
                                                                                                    }}>
                                                                                                        <IconSearch color="informative"
                                                                                                                    size="24"
                                                                                                                    title={this.props.language.language?.warranty.watchFile!}
                                                                                                                    className=""/>
                                                                                                    </a>
                                                                                                ):null}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>


                                                                                {i.url? (
                                                                                 <>
                                                                                 {(this.state.read == false) &&
                                                                                     <AuthorizationDocWarranty
                                                                                         idDocumento={i.idDocumento}
                                                                                         idTipoDocumento={i.idTipoDocumento}
                                                                                         idWarranty={this.state.idWarranty}
                                                                                         returnDocStatus={ ((statusDoc: number) => {
                                                                                             this.receiveDocStatus(statusDoc, index);
                                                                                         })}
                                                                                     />

                                                                                 }

                                                                                 </>

                                                                                ):<Button variant="secondary" size="small">
                                                                                    <IconBrokenLink color="caution"  />
                                                                                    </Button>}


                                                                            </div>

                                                                            <NegativeAlert textAlert={i.msgExpendiente!}/>

                                                                            {
                                                                                i.detalle.map((iDetail, index2) =>
                                                                                    (
                                                                                        <div key={index2}>
                                                                                            {iDetail.inputType == inputTypeWarranty.Date  ? (
                                                                                                <div>
                                                                                                    <label className="label-input">
                                                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                                                        {iDetail.descripcion}
                                                                                                    </label>
                                                                                                    <input
                                                                                                        type="date"
                                                                                                        className={"input-sdgf"}
                                                                                                        id={String(index)}
                                                                                                        name={String(index2)}
                                                                                                        value={iDetail.valor!}
                                                                                                        disabled={true}
                                                                                                    />
                                                                                                </div>
                                                                                            ): null}
                                                                                            {iDetail.inputType == inputTypeWarranty.String  ? (
                                                                                                <div>
                                                                                                    <label className="label-input">
                                                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                                                        {iDetail.descripcion}
                                                                                                    </label>
                                                                                                    <AlphanumericInput
                                                                                                        id={String(index)}
                                                                                                        filter={/^.{1,500}$/}
                                                                                                        name={String(index2)}
                                                                                                        labelVisual={iDetail.descripcion}
                                                                                                        value={iDetail.valor!}
                                                                                                        disabled={true}
                                                                                                    />

                                                                                                </div>

                                                                                            ): null}
                                                                                            {iDetail.inputType == inputTypeWarranty.Double  ? (
                                                                                                <div>
                                                                                                    <label className="label-input">
                                                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                                                        {iDetail.descripcion}
                                                                                                    </label>

                                                                                                    <input type="text"
                                                                                                           className="input-sdgf"
                                                                                                           id={String(index)}
                                                                                                           name={String(index2)}
                                                                                                           value={Number(iDetail.valor)}
                                                                                                           disabled={true}/>


                                                                                                </div>

                                                                                            ): null}

                                                                                            {iDetail.inputType == inputTypeWarranty.LongText  ? (
                                                                                                <div>
                                                                                                    <label className="label-input">
                                                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                                                        {iDetail.descripcion}

                                                                                                    </label><br/>
                                                                                                    <textarea
                                                                                                        id={String(index)}
                                                                                                        name={String(index2)}
                                                                                                        className="input-sdgf"
                                                                                                        maxLength={500}
                                                                                                        disabled={true}
                                                                                                    >
                                                                                                {iDetail.valor!}
                                                                                            </textarea>


                                                                                                </div>
                                                                                            ): null}

                                                                                            {iDetail.inputType == inputTypeWarranty.Select  ? (
                                                                                                <div>
                                                                                                    <label className="label-input">
                                                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                                                        {iDetail.descripcion}
                                                                                                    </label><br/>

                                                                                                        <ExpedienteSelect
                                                                                                        idCatalogo={iDetail.idCatalogo}
                                                                                                        idSubCatalogo={iDetail.idSubCatalogo}
                                                                                                        value={iDetail.valor}
                                                                                                        idGarantia={this.state.garantiaObject.idGarantia!}
                                                                                                        disabled={(validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS))?false: true}
                                                                                                        onValueChange={(catalogo, subcatalogo,value) => {
                                                                                                            const auxListExpediente: ExpedienteModel[] = this.state.listExpediente;
                                                                                                            auxListExpediente[index].detalle[index2].idSubCatalogo = subcatalogo
                                                                                                            if (value){
                                                                                                                auxListExpediente[index].detalle[index2].valor = value!.toString();
                                                                                                            }
                                                                                                            this.setState({listExpediente: auxListExpediente})
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            ): null}

                                                                                            <NegativeAlert textAlert={iDetail.msgExpendiente!}/>

                                                                                        </div>

                                                                                    ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                            }
                                                            {
                                                                (this.state.listExpediente.length==0)?(
                                                                    <div className="col-md-12 ">
                                                                        {this.props.language.language?.warranty.notFoundDocs!}
                                                                    </div>

                                                                ): null
                                                            }

                                                        </div>
                                                    ) : <div className="col-md-12 expedient-files">
                                                        <NegativeAlert textAlert= {this.props.language.language?.warranty.notFoundDocs!}/>
                                                    </div>
                                                    }

                                                    <div className="col-md-12 buttons-navigation">
                                                        <div className="row">

                                                            <div className="col-md-6">
                                                                <br/>
                                                                <Button dynamicWidth
                                                                        variant="secondary"
                                                                        onClick={() => this.back()}>
                                                                    {this.props.language.language?.warranty.btnReturn!}
                                                                </Button>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <br/>
                                                                {!this.state.sendingExpediente ? (
                                                                        <Button dynamicWidth
                                                                                variant="primary"
                                                                                onClick={() =>this.saveDocs()}>
                                                                            {this.props.language.language?.warranty.btnContinue!}
                                                                        </Button>
                                                                    ):
                                                                    <ButtonSpinner label={this.props.language.language?.warranty.btnSending!}/>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ):
                                            <div className="col-12">
                                                <NegativeAlert textAlert={this.props.language.language?.warranty.notFoundDocs!}/>

                                            </div>
                                ): null}

                                {this.state.estadoCaptura == 3 ? (
                                    this.state.loadingPropietarioGarantia ? (
                                            <div className="col-12">
                                                <SpinnerLoading text={this.props.language.language?.warranty.loadingPropietary!}/>
                                            </div>
                                        ) :
                                        (!this.state.errorPropietarioGarantia)?(
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {this.props.language.language?.warranty.labelReview1!}
                                                    {this.state.garantiaObject.idGarantia}
                                                    {this.props.language.language?.warranty.labelReview2!}
                                                    <br/><br/>

                                                </div>

                                                <div className="col-md-12">
                                                    <MessagesAPIComponent idAPI={1}
                                                                          disableCreate={validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR) || this.state.read}
                                                                          idEspecifico={this.state.garantiaObject.idGarantia!}
                                                                          emailRemitente={this.state.PERFIL.email}
                                                                          nombreRemitente={this.state.PERFIL.username}
                                                                          emailDest={this.state.propietarioGarantia.correoPara}
                                                                          nombreDest={this.state.propietarioGarantia.para}
                                                                          TOKEN={this.state.PERFIL.accessToken}
                                                                          APIURL={environment.APIMENSAJES}
                                                                          APIKEY={environment.APIKEYMENSAJES}
                                                    />

                                                </div>


                                                <div className="col-md-12 buttons-navigation">
                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <br/>
                                                            <Button dynamicWidth
                                                                    variant="secondary"
                                                                    onClick={() => {
                                                                        // abrir el expediente
                                                                        this.openExpedient(this.state.garantiaObject.idGarantia!); }
                                                                    }>
                                                                {this.props.language.language?.warranty.btnReturn!}
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <br/>
                                                            <Button dynamicWidth
                                                                    variant="primary"
                                                                    onClick={() => this.openDictamen(this.state.garantiaObject.idGarantia!)}>
                                                                {this.props.language.language?.warranty.btnContinue!}
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            ):
                                            <div className="col-12">
                                                <NegativeAlert
                                                    textAlert={this.props.language.language?.warranty.errorLoadPropietary!}/><br/>
                                                <div className="row">
                                                    <div className="col-md-4"/>

                                                    <div className="col-md-4">
                                                        <Button dynamicWidth
                                                                variant="secondary"
                                                                onClick={() => {
                                                                    // abrir el expediente
                                                                    this.openMessages(this.state.idWarranty)}
                                                                }>
                                                            {this.props.language.language?.warranty.reintent!}
                                                        </Button>
                                                    </div>
                                                    <div className="col-md-4"/>

                                                </div>

                                            </div>
                                ) : null}

                                {this.state.estadoCaptura == 4 ? (
                                    <>
                                        <AuthorizationDictumWarranty
                                            idWarranty={this.state.idWarranty}
                                            idFiado={this.state.idFiado}
                                            visibleButtons={this.state.read? false: true}
                                            quoteAmount={this.state.quoteAmont!}
                                            quotePercent={this.state.quotePercent!}
                                        />

                                        <div className="col-md-12 buttons-navigation">
                                            <div className="row">

                                                <div className="col-md-12">
                                                    <br/>
                                                    <Button dynamicWidth
                                                            variant="secondary"
                                                            onClick={() => {
                                                                // abrir mensajes
                                                                this.openMessages(this.state.garantiaObject.idGarantia!); }
                                                            }>
                                                        {this.props.language.language?.warranty.btnReturn!}
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                    </>

                                    ): null}


                            </Form>

                        </div>

                    </div>
                </div>
            </div>



        </>
    }

    componentDidMount(){
        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
            validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR) ||
            validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)){
            this.asignIdWarranty(this.state.idWarranty);
            this.getTiposUso();
            this.getClaves();
            this.getMoneda();
            if(this.props.match.params.idFiado){
                const auxIdFiado =  parseInt(this.props.match.params.idFiado);
                this.setState({idFiado: auxIdFiado})

            }
            if (this.props.match.params.idFolio){
                const auxIdWarranty = parseInt(this.props.match.params.idFolio);
                this.setState({idWarranty: auxIdWarranty})
                this.getWarrantyDetail(auxIdWarranty);

            }
            // Query params para solo revisar las garantìas
            const valores = window.location.search;
            const urlParams = new URLSearchParams(valores);
            const read = urlParams.get("read");
            if (read){
              //  alert('review ' + review)
                this.setState({read: true})
            }

            if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)
                || validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR )
            ){
                this.setState({showObligors: true});
            }

            let infoPrincipal = $('#seccion-warranty-review').width();

            let show = false;
            $(".comments-warranty-review").click(function(){
                console.log(infoPrincipal)

                $( "#comentarios-warranty-review" ).toggle({duration:"slow",
                    start(animation: JQuery.Animation<HTMLElement>) {
                        if (!show){
                            show = true;
                            $( '#seccion-warranty-review').removeClass( "col-md-12" );
                            $( '#seccion-warranty-review').addClass( "col-md-9" );
                            $('#warranty-review').css({ "padding-left": "3%"});
                            $('#warranty-review').css({ "padding-right": "0px"});


                        }else {
                            show = false
                            $( '#seccion-warranty-review').addClass( "col-md-12" );
                            $( '#seccion-warranty-review').removeClass( "col-md-9" );
                            $('#warranty-review').css({ "padding-left": "7%"});
                            $('#warranty-review').css({ "padding-right": "7%"});
                        }

                    },

                });

            });
        }
        else {
            this.props.history.push('/401');
        }





    }
    // asignarle el cliente a la garantía
    asignIdWarranty(idClient: number){
        this.setState(prevState => ({
            garantiaObject: { ...prevState.garantiaObject, idCliente: idClient }
        }));
    }

    async getTiposUso(): Promise<void> {
        const res = await garantiasService.getTipoUsoGarantias();
        this.setState({listTiposUso: res.data})
    }

    async getClaves(): Promise<void> {
        const res = await garantiasService.getClavestipo();
        this.setState({listClaves: res.data})
    }

    async getMoneda(): Promise<void> {
        const res = await apiService.getCatalogoMoneda();
        this.setState({listMonedas: res.data})
    }
    async putPorcentajePonderacion(garantiaObject: GarantiaModel) {
        const res = await garantiasService.putPorcentajePonderacion(garantiaObject);
        const auxListPercents: PorcentajePonderacion [] = res.data;
        this.setState({ stablishPorcentajeCalificadora: false})
        if (auxListPercents.length>0){

            for (const i of  res.data){
                if (i.automatico){
                    if (i.porcentajePonderacion == 0 && auxListPercents.length == 1 ){
                        this.setState({recommendMontoPonderado: 0, stablishPorcentajeCalificadora: true})
                        break;
                    }

                    if (!validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)){
                        this.setState({ stablishPorcentajeCalificadora: true})
                    }
                    else{
                        let montoPonderadoAux = 0;
                        let porcentajePonderacion = this.state.garantiaObject.porcentajePonderacion;
                        if (this.state.garantiaObject.porcentajePonderacion){
                            montoPonderadoAux = formatValue(this.state.garantiaObject.monto!) * porcentajePonderacion;
                            montoPonderadoAux = Number(montoPonderadoAux.toFixed(2));
                        }else{
                            porcentajePonderacion = i.porcentajePonderacion;
                            montoPonderadoAux = formatValue(this.state.garantiaObject.monto!) * porcentajePonderacion;
                            montoPonderadoAux = Number(montoPonderadoAux.toFixed(2));
                        }

                        this.setState(prevState => ({
                            garantiaObject: { ...prevState.garantiaObject,
                                porcentajePonderacion: porcentajePonderacion,
                                montoPonderado: montoPonderadoAux,
                            }
                        }));
                        this.setState({recommendMontoPonderado: i.porcentajePonderacion})
                    }

                }
            }

        }
        this.setState({listPercents: res.data})
    }

    async saveWarranty(): Promise<void> {

        if (this.state.read){
            this.openExpedient(this.state.idWarranty);
        }else {
            if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)){
                if (this.isValidWarranty(this.state.garantiaObject, true, true)){
                    // abrir el expediente
                    this.openExpedient(this.state.idWarranty);
                }
            }else {
                this.openExpedient(this.state.idWarranty);
            }
        }


    }

    public amonutQuoted (quoteAmonutAux: CalificacionMontoModel) {
        this.setState({quoteAmont: quoteAmonutAux})
        if (quoteAmonutAux.estadoMonto == statesQuoteDoc.aprobado ||
            quoteAmonutAux.estadoMonto == statesQuoteDoc.rechazado ){
            this.setState({quotedAmountWarranty: true});
            this.setState(prevState => ({
                garantiaMsgs: { ...prevState.garantiaMsgs, msgMonto: ''}
            }))
        }
    }

    public percentQuoted (quoteAmonutAux: CalificacionPorcentajeModel) {
        this.setState({quotePercent: quoteAmonutAux})
        if (quoteAmonutAux.estadoPorcentaje == statesQuoteDoc.aprobado ||
            quoteAmonutAux.estadoPorcentaje == statesQuoteDoc.rechazado ){
            this.setState({quotedPercent: true});
        }
        this.setState(prevState => ({
            garantiaMsgs: { ...prevState.garantiaMsgs, msgPorcentaje: ''}
        }))
    }

    public vigencyQuoted (quoteVigency: CalificacionVigenciaModel) {
        this.setState({quoteVigency: quoteVigency})
        if (quoteVigency.estadoVigencia == statesQuoteDoc.aprobado ||
            quoteVigency.estadoVigencia == statesQuoteDoc.rechazado ){
            this.setState({quotedVigency: true});
        }
        this.setState(prevState => ({
            garantiaMsgs: { ...prevState.garantiaMsgs, msgVigency: ''}
        }))
    }

    public async handleInputChangeSelect (e: InputChange){
        const name = e.target.name;
        let value = e.target.value;

        switch (name){

            case 'porcentajePonderacion':
                let montoPonderadoAux = formatValue(this.state.garantiaObject.monto!) * parseFloat(value);
                montoPonderadoAux = Number(montoPonderadoAux.toFixed(2)); //  redondear a dos decimales
                const garantiaAux: GarantiaModel = this.state.garantiaObject;
                garantiaAux.porcentajePonderacion = formatValue(value);
                garantiaAux.montoPonderado = montoPonderadoAux;

                if (this.validMonto(garantiaAux.monto)){
                    // actualizar el monto de ponderación
                    try {
                        const res = await garantiasService.putGarantia(garantiaAux, false);
                        this.setState({garantiaObject: res.data});


                    } catch (error) {
                        const err = error as AxiosError
                        if (err.response) {

                        }
                    }
                }



                break;
        }


    }

    public isValidWarranty(warranty: GarantiaModel, validarFechas: boolean, validarPorcentaje: boolean): boolean{
        this.setState({garantiaMsgs: initialGarantiaMsg})
        if (!this.validMonto(warranty.monto)){
            return false;
        }
        if(!this.state.quotedAmountWarranty){

            this.setState(prevState => ({
                garantiaMsgs: { ...prevState.garantiaMsgs, msgMonto: this.props.language.language?.warrantyAuthorization.quoteAmount!}
            }))
            return  false;
        }
        if (validarPorcentaje){
            if (!warranty.porcentajePonderacion || !warranty.montoPonderado || !this.state.quotedPercent){
                let msg = this.props.language.language?.warranty.valdatePercent!;

                if (!this.state.quotedPercent){
                    msg  = this.props.language.language?.warrantyAuthorization.quotePercent!
                }
                this.setState(prevState => ({
                    garantiaMsgs: { ...prevState.garantiaMsgs, msgPorcentaje: msg}
                }))
                return false;
            }
        }

        if(!this.state.quotedVigency){

            this.setState(prevState => ({
                garantiaMsgs: { ...prevState.garantiaMsgs, msgVigency: this.props.language.language?.warrantyAuthorization.quoteVigency!}
            }))
            return  false;
        }



        return true;
    }


    public validMonto(monto: number): boolean{
        if (monto <= 0 || !monto){
            this.setState(prevState => ({
                garantiaMsgs: { ...prevState.garantiaMsgs, msgMonto: this.props.language.language?.warranty.valselectCurrency!}
            }))
            return false;
        }
        return true;

    }

    async getExpedient(idGarantia: number) {
        this.setState({loadingExpediente:true, errorExpediente: false})
        try {
            const res = await garantiasService.getExpediente(idGarantia);
            const listExpediente: ExpedienteModel[] = res.data;
            this.setState({listExpediente: listExpediente, loadingExpediente:false})

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingExpediente:false, errorExpediente: true})

            }
        }
    }

    async saveDocs(): Promise<void> {

        if (this.state.read == true){
            this.openMessages(this.state.idWarranty);

        }else {
           if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)){
                if (this.isValidWarranty(this.state.garantiaObject, true, true)){
                    this.setState({sendingExpediente: true});

                    // obtener una sublista de los documentos obligatorios
                    const auxListExpediente = this.state.listExpediente;

                    let subListOblibatorios: ExpedienteModel [] = [];
                    for (const doc of auxListExpediente){
                        if (doc.obligatorio){
                            subListOblibatorios.push(doc);
                        }
                    }

                    // validar documentos obligatorios
                    let index = 0;
                    let allDocsValid = true;

                    for (const doc of subListOblibatorios){
                        console.log('valid document: '+ this.isValidDocument(doc,index))
                        if (!this.isValidDocument(doc, index)) { // validar primero documento por documento
                            allDocsValid = false;
                            break;
                        }
                        index++;
                    }

                    //Si tiene todos los documentos validos ir hacía adelante
                    if (allDocsValid){
                        this.setState({sendingExpediente: false});
                        this.openMessages(this.state.idWarranty);
                    }else {
                        this.setState({sendingExpediente: false});
                        this.openMessages(this.state.idWarranty);
                    }
                }
            }else {

                this.openMessages(this.state.idWarranty);
            }
        }


    }

    public isValidDocument(doc: ExpedienteModel, index: number): boolean{
        // inicializar los mensajes vacíos
        let auxListExpedientes = this.state.listExpediente;

        doc.msgExpendiente= ''
        doc.detalle.map((i,index) =>{
            i.msgExpendiente = '';
        })

        auxListExpedientes[index] = doc;

        this.setState({listExpediente: auxListExpedientes})


        let validDoc = true;
        if (doc.obligatorio){
            if (!doc.idDocumento || !doc.url){
                doc.msgExpendiente= this.props.language.language?.warranty.notUploadFile + ' ' + doc.descripcion
                validDoc = false;
            }
            doc.detalle.map((i,index) =>{
                if (!i.valor){
                    validDoc = false;
                    i.msgExpendiente = this.props.language.language?.warranty.notEnterInfo + ' ' + i.descripcion
                }
            })
        }



        auxListExpedientes[index] = doc;

        this.setState({listExpediente: auxListExpedientes})

        return validDoc;
    }

    public openExpedient(idGarantia: number) {
       this.getExpedient(idGarantia);
        this.setState({estadoCaptura: 2})
        window.scrollTo(0, 0);
    }

    private async getWarrantyDetail(idGarantia: number) {
        this.setState({loadingGarantia: true, errorGarantia: false})
        try {
            const res = await garantiasService.getGarantiaDetail(idGarantia);
            const auxGarantiaObject: GarantiaModel = res.data;
            this.setState({garantiaObject: auxGarantiaObject, loadingGarantia: false})
            await this.getWarrantyDireccionada(auxGarantiaObject.idGarantia!);

            await this.putPorcentajePonderacion(auxGarantiaObject);
            if(auxGarantiaObject.clientesExclusiva.length == 1 &&
                auxGarantiaObject.clientesExclusiva[0].idCliente == auxGarantiaObject.idCliente){
                this.setState({isExclusiveForPrincipal: false})

            }else if (auxGarantiaObject.clientesExclusiva.length >= 1){
                this.setState({isExclusiveForPrincipal: true})
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingGarantia: false, errorGarantia: true})

            }
        }
    }
    private async getWarrantyDireccionada(idWarranty: number){
        try {
            const res = await garantiasService.getGarantiaDireccionada(idWarranty);
            const auxGarDirect: GarantiaDireccionada = res.data;
            this.setState({garantiaDireccionada: auxGarDirect})


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }
    public openMessages(idGarantia: number) {
        this.getPropietarioWarranty(idGarantia);
        this.setState({estadoCaptura: 3})
        window.scrollTo(0, 0);


    }

    private openDictamen(idGarantia: number) {
        this.setState({estadoCaptura: 4})
        window.scrollTo(0, 0);
    }

    public openEstadoCaptura(show: number){
        show = show+1;
      //  alert('estado de captura: '+ this.state.estadoCaptura + ' == quiero ver el ' + (show)  )


            this.setState({estadoCaptura: show});
            switch (show){
                case 1:
                    if (this.state.idWarranty){
                        this.getWarrantyDetail(this.state.idWarranty);
                    }

                    break;
                case 2:
                    if (this.state.idWarranty){
                        this.openExpedient(this.state.idWarranty);
                    }

                    break;
                case 3:
                    if (this.state.idWarranty){
                        this.openMessages(this.state.idWarranty);
                    }
                    break;
                case 4:
                    if (this.state.idWarranty){
                        this.openDictamen(this.state.idWarranty);
                    }
                    break;
            }


    }

    next(): void {
        this.setState({estadoCaptura: this.state.estadoCaptura + 1})
    }

    back(): void {
        this.setState({estadoCaptura: this.state.estadoCaptura - 1})
    }

    private async getPropietarioWarranty(idWarranty: number) {
        this.setState({loadingPropietarioGarantia: true, errorPropietarioGarantia: false})
        try {
            const res = await garantiasService.getPropietarioGar(idWarranty);
            console.log(res.data);
            this.setState({propietarioGarantia: res.data, loadingPropietarioGarantia: false})
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingPropietarioGarantia: false, errorPropietarioGarantia: true})

            }
        }
    }

    public initMontoPonderado(){
        this.setState(prevState => ({
            garantiaObject: { ...prevState.garantiaObject, montoPonderado: 0, porcentajePonderacion: 0 }
        }))
        this.setState(prevState => ({
            garantiaMsgs: { ...prevState.garantiaMsgs, msgPorcentaje: '', msgSelectAmmountNotRecommend: ''}
        }))
        this.setState({recommendMontoPonderado:0})


    }


    public receiveDocStatus (statusDoc: number, indexPos: number){
        const auxListExpediente = this.state.listExpediente;
        const auxDocExpediente: ExpedienteModel = auxListExpediente[indexPos];
        auxDocExpediente.estado = statusDoc
        auxListExpediente.splice(indexPos,1, auxDocExpediente);
        this.setState({listExpediente: auxListExpediente})
    }


}

interface WarrantyReviewState {
    idFiado: number;
    idWarranty: number;
    estadoCaptura: number;
    listTiposUso: TiposUsoGarantias[];
    listClaves: ClavesGarantias[];
    listMonedas: MonedaModel[];
    listExpediente: ExpedienteModel[];
    listPercents: PorcentajePonderacion[];
    loadingExpediente: boolean;
    errorExpediente: boolean;
    sendingExpediente: boolean;
    sendingGanrantia: boolean;
    garantiaObject: GarantiaModel;
    garantiaDireccionada: GarantiaDireccionada;
    loadingGarantia: boolean;
    errorGarantia: boolean;
    quotedAmountWarranty: boolean;
    quotedPercent: boolean;
    quotedVigency: boolean;
    quoteAmont: CalificacionMontoModel;
    quotePercent: CalificacionPorcentajeModel;
    quoteVigency: CalificacionVigenciaModel;
    garantiaMsgs: GarantiasMessages;
    propietarioGarantia: PropietarioGarantiaModel;
    loadingPropietarioGarantia: boolean;
    errorPropietarioGarantia: boolean;
    idGrantiaQueryParam: number;
    PERFIL: UserModel;
    progresoGarantia: ProgresoGarantiaModel;
    isExclusiveForPrincipal: boolean;
    recommendMontoPonderado: number;
    stablishPorcentajeCalificadora: boolean;
    showObligors: boolean;
    openComentario: boolean;
    read: boolean;

}
const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});

export default connect(mapStateToProps) (WarrantyReview);
