import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import * as apiTokenService from "../../../services/api-session/ApiSession";
import logo from '../../../assets/images/public/logo-web-liberty16.png';
import './SinginURB.scss';
import { AxiosError } from "axios";
import { NegativeAlert } from "../../../components/public/negative-alert/NegativeAlert";
import { Button } from "@lmig/lmds-react";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { validRoles } from "../../../class/CommonClass";
import { UserModel } from "../../../models/public/UserModel";
interface ISection {
    id: number;
}
interface Params {
    urb: string;
    clave: string;
    origen: string;
}

interface LocationState {
}

interface SC {
    statusCode?: number;
}
class SinginURB extends Component<ISection & RouteComponentProps<Params, SC, LocationState>, SinginURBState> {

    public URB: string;
    public clave: string;
    public origin: string;
    public localStorageClass = new LocalStorageClass();
    public PERFIL = (this.localStorageClass.getProfile())
    constructor(props: any) {
        super(props);
        this.URB = this.props.match.params.urb;
        this.clave = this.props.match.params.clave;
        this.origin = this.props.match.params.origen;
        //https://lf-sgf-frontend-test.us-east-1.np.paas.lmig.com/cargando-sdgf/
        // ol8SzarMvA58sds32DY8mrsXsSI0mgjCOyh7CbRO_vc./
        // 488/
        // garantias
        document.title = "Cargando "
        this.state = {
            loading: true,
            errorSinginURB: false,
        }



    }
    render() {
        return <>
            <div id="singin-urb">
                <img src={logo} alt="Logo" id="logo-liberty" className="img-fluid d-block mx-auto" />
                {this.state.loading ? (
                    <>
                        <SpinnerLoading text={"Iniciando Sesión"} />
                    </>
                ) :
                    <>
                        <br />

                        <NegativeAlert
                            textAlert={'Lo sentimos un error ha pasado, revise su conexión y vuela a intentarlo.'} />

                        <br />
                        <Button dynamicWidth
                            variant="primary"
                            onClick={() => this.getSignURB(this.URB)}>
                            Reintentar
                        </Button>
                    </>
                }

            </div>

        </>
    }
    componentDidMount() {

        this.getSignURB(this.URB);

    }

    private async getSignURB(urb: string) {
        this.setState({ loading: true, errorSinginURB: false })
        try {
            const res = await apiTokenService.getSigninUrb(urb);
            if (this.clave) {
                res.data.clave = this.clave;
            }
            await this.localStorageClass.setSomeString(this.localStorageClass.PERFIL, JSON.stringify(res.data));
            // esperar un segundo para redireccionar
            await new Promise(done => setTimeout(() => {
                    this.redirect(this.origin, res.data)
            }, 1000));
            await this.setState({ loading: false })
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({ loading: false, errorSinginURB: true })
            }
        }

    }


    private async redirect(origin: string, perfil: UserModel) {
        switch (origin) {
            case originSerf.warranties:
                await this.props.history.push('/garantias');
                break;
            case originSerf.engangments:
                await this.props.history.push('/compromisos');
                break;
            case originSerf.creditbureau:
                
                if (validRoles(perfil.roles, this.localStorageClass.AGENTE)) {
                    await this.props.history.push('/buro-credito/Solicitudes')
                } else{
                    await this.props.history.push('/buro-credito');
                }

                break;
            case originSerf.polizasRH:
                await this.props.history.push('/rh/polizas');
                break;
            case originSerf.tesoreria:
                await this.props.history.push('/tesoreria');
                break;
            case originSerf.reporte_garantias:
                await this.props.history.push('/reporte-garantias');
                break;
            case originSerf.bajas_reclamaciones:
                await this.props.history.push('/bajas-reclamaciones');
                break;
        }
    }
}
interface SinginURBState {
    loading: boolean,
    errorSinginURB: boolean

}
export enum originSerf {
    warranties = 'garantias',
    engangments = 'compromisos',
    creditbureau = 'buro-credito',
    polizasRH = 'polizasrh',
    tesoreria = 'tesoreria',
    reporte_garantias = 'reporte-garantias',
    bajas_reclamaciones = 'bajas-reclamaciones'
}

export default SinginURB;
